import { Component, Input, OnInit } from '@angular/core';
import { CoreService } from 'src/app/service/core.service';

@Component({
  selector: 'app-invoice-with-header-footer-temp5',
  templateUrl: './invoice-with-header-footer-temp5.component.html',
  styleUrls: ['./invoice-with-header-footer-temp5.component.css']
})
export class InvoiceWithHeaderFooterTemp5Component implements OnInit {

  @Input() saleQtnData;
  @Input() print_style;
  @Input() qr_inv;
  @Input() savedTemplate;
  @Input() is_prew_description;
  @Input() payTypes;
  @Input() cmpny;
  @Input() previewLoader;
  @Input() logoImgUrl;
  @Input() tempImgUrl;
  @Input() footImgUrl;
  @Input() sales_print_name;
  @Input() hide_item_discount;
  @Input() hide_vat;
  @Input() sales_settings;
  country_dec: any;

  // pc / dip = inch
  // inch * 25.4 = mm
  // in mm milli meter ( pixcel / dip * 25.4)
  // 130px means 130 / 96 * 25.4 =  34.3958
  // headerheightInmm = 34.4;

  // 73.5mm (278px) maximum height allowed 
  headerheightInmm = 34.4;

  // 130px means 130 / 96 * 25.4 =  31.75
  /// 73.5mm (278px) maximum height allowed 
  footerheightInmm = 31.75;

  constructor(private coreService: CoreService) { }

  ngOnInit() {

    this.country_dec = this.coreService.setDecimalLength();

    console.log(this.savedTemplate.page_no_display);


  }

  ngOnChanges(changes) {
    if (changes['saleQtnData'] && this.saleQtnData) {

      this.headerheightInmm = this.savedTemplate.header_height;
      this.footerheightInmm = this.savedTemplate.footer_height;

    }
  }

  startPrint() {
    const contentHeight = document.getElementById("printContentArea").scrollHeight;
    var dpi = this.getDPI();
    var contentHeightinmm = (contentHeight / dpi * 25.4) + this.headerheightInmm + this.footerheightInmm;


    var totalPage = Math.ceil(contentHeightinmm / 297);

    if (this.savedTemplate.header_type != 1) {
      var footerImgDiv = document.createElement("div");
      footerImgDiv.setAttribute("id", "footerImgDiv");
      footerImgDiv.style.position = "absolute";
      footerImgDiv.style.color = "#000";
      footerImgDiv.style.width = "100%";
      footerImgDiv.innerHTML += "<img src = " + this.footImgUrl + " width='100%'>";
      footerImgDiv.style.top = "calc((" + totalPage + " * (287mm - 0.5px)) - " + this.footerheightInmm + "mm)";
    }

    $('#print-sales').append(footerImgDiv);
    $('#printButton').click();
  }

  getDPI() {
    var div = document.createElement('div');
    div.style.display = 'block';
    div.style.height = '100mm';
    div.setAttribute("id", "my_mm");
    document.body.appendChild(div);
    var px = $('#my_mm').height();
    return Math.round(px / 3.93901);
  }
}

